.input-wrapper {
  position: relative;
  width: 100%;
}

.input-group {
  position: relative;
  width: 100%;
}

.form-input {
  width: 100%;
  padding: 0.75rem 0;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #4a4a4a;
  color: white;
  font-size: 1.5rem;
}

.input-label {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #808080;
  pointer-events: none;
  font-size: 1.5rem;
}

.icon {
  height: 16px;
  width: 16px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
}

.icon-success {
  color: #3eb6fb;
}

.icon-error {
  color: #ff4444;
}

.error {
  color: #ff4444;
  font-size: 1rem;
  position: absolute;
  left: 0;
  bottom: -1.5rem;
  display: flex;
  align-items: center;
  gap: 4px;
}

.required {
  color: #3b82f6;
  margin-left: 2px;
}

.form-input:focus ~ .input-label,
.form-input:not(:placeholder-shown) ~ .input-label {
  display: none;
}

.form-input:focus {
  outline: none;
  border-bottom-color: #3eb6fb;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  -webkit-text-fill-color: white !important;
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent !important;
}
