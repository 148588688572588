.hero-user-quote {
  font-size: 2rem;
  font-weight: 500;
  line-height: 3rem;
  font-style: italic;
  color: var(--white);
  max-width: 46rem;
  text-transform: capitalize;
  margin-bottom: 2.5rem;
  min-height: 150px;
  display: flex;
  align-items: flex-end;
}

.hero-feedback {
  display: flex;
  align-items: center;
  min-width: fit-content;
}
.hero-feedback > div {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}
.hero-feedback > div p {
  margin: 0;
}
.hero-feedback img {
  width: 7rem;
  height: 7rem;
}
.hero-feedback-name {
  font-weight: bold;
  color: #ffffff;
  font-size: 2rem;
  line-height: 3rem;
}
.hero-feedback-position {
  font-weight: normal;
  color: #3eb6fb;
  font-size: 2rem;
  line-height: 3rem;
}
.quote-container {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 2rem;
  margin-top: 4rem;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border: 1px solid #454f63;
  gap: 2rem;
}

.main-quote {
  font-size: 2rem;
  color: #3eb6fb;
}

.quote-icon {
  z-index: 1;
  position: absolute;
  top: -20px;
}
@media (max-width: 768px) {
  .quote-container {
    flex-direction: column;
  }
  .main-quote {
    font-size: 1.5rem;
  }
  .smaller-position {
    font-size: 1.5rem;
  }
}
