.maps-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 15px;
}
.maps-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
}
.maps-wrapper > div {
  display: flex;
  flex-direction: column;
}
.map-title {
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 2.5rem;
  font-style: normal;
  text-align: left;
  color: #ffffff;
}

@media screen and (max-width: 750px) {
  .maps-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .maps-wrapper svg {
    width: 100%;
  }
  .map-title {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}
