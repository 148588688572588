.career-connector-section {
  min-height: 100vh;
  padding: 4rem;
  color: black;
  position: relative;
  overflow: hidden;
  padding-top: 12rem;
  z-index: 1;
  background-color: #ffffff;
}

.career-connector-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4rem;
  padding-top: 2rem;
}

.text-container {
  flex: 0 0 40%;
  z-index: 2;
}

.career-connector-title {
  font-size: 2.75rem;
  font-weight: bold;
  color: #000000;
  margin-bottom: 2rem;
}

.career-text {
  font-size: 1.5rem;
  padding-bottom: 2rem;
  color: #000000;
}

.career-list {
  list-style: disc;
  padding-left: 2rem;
  padding-bottom: 1rem;
  margin: 0;
}

.career-list li {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #000000;
}

.customer-image-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 4rem;
}

.customer-image {
  max-width: 90%;
  max-height: 90%;
  height: auto;
  display: block;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1024px) {
  .career-connector-section {
    padding-left: 4rem;
    padding-top: 12rem;
  }
  .career-connector-container {
    flex-direction: column;
  }
  .career-connector-title {
    margin-top: 2rem;
  }
  .text-container {
    order: 1;
  }
  .customer-image-container {
    order: 0;
  }
}

@media screen and (max-width: 768px) {
  .text-container {
    flex: 1;
    padding-right: 0;
  }

  .career-connector-section {
    padding: 2rem;
    padding-top: 12rem;
  }

  .sub-title {
    font-size: 1.75rem;
    margin-top: 2rem;
  }

  .text,
  .career-title,
  .career-list li,
  .career-list li::before {
    font-size: 1.5rem;
  }
}
