.password-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  min-height: 90vh;
}

.password-form {
  width: 100%;
  max-width: 400px;
}

.input-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.submit-button {
  width: fit-content;
  padding: 1rem 2rem;
  margin-top: 2rem;
  background-color: #3eb6fb;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.5rem;
  font-weight: 500;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.submit-button:hover {
  background-color: #2b9fe3;
}

.arrow-icon {
  height: 24px;
  width: 24px;
  color: #3db6fb;
}

.error-message {
  margin-top: 2rem;
  color: red;
  font-size: 1rem;
}
