@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
:root {
  font-size: 10px;
  --black: #000000;
  --white: #ffffff;
}
html,
body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}
a {
  text-decoration: none;
  cursor: pointer;
}
button {
  cursor: pointer;
}
g,
rect,
path {
  will-change: transform, opacity;
}
section {
  width: 100%;
}
.box {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
}
.podio {
  width: 100%;
  background-color: var(--black);
  overflow: hidden;
}
.applications-section {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 12.5rem 0;
}
.applications-section::before {
  content: '';
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.applications-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.applications-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main-title {
  font-size: 7.5rem;
  font-weight: bold;
  line-height: 8rem;
  text-align: left;
  color: var(--white);
}
.description {
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 2.5rem;
  text-align: left;
  color: var(--white);
  margin: 2.5rem 0;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.span-3 {
  grid-column: span 3;
}
.span-4 {
  grid-column: span 4;
}
.span-5 {
  grid-column: span 5;
}
.span-6 {
  grid-column: span 6;
}
.span-7 {
  grid-column: span 7;
}
.span-12 {
  grid-column: span 12;
}
.margin-b-3 {
  margin-bottom: 3rem;
}
.margin-t-2 {
  margin-top: 2rem;
}
.margin-b-5 {
  margin-bottom: 5rem;
}
.padding-r-5 {
  padding-right: 5rem;
}
.padding-l-5 {
  padding-left: 5rem;
}
.width-100 {
  width: 100%;
}
.font-bold {
  font-weight: bold;
}
.padding-top-2 {
  padding-top: 2rem;
}
.padding-top-3 {
  padding-top: 3rem;
}
.padding-top-4 {
  padding-top: 4rem;
}
.padding-top-6 {
  padding-top: 6rem;
}
.padding-bottom-10 {
  padding-bottom: 10rem;
}
.padding-top-10 {
  padding-top: 10rem;
}
.padding-top-20 {
  padding-top: 20rem;
}
.grid-10 {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}

@media screen and (max-width: 1660px) {
  .box {
    padding: 0 5rem;
  }
}

@media screen and (max-width: 1250px) {
  .main-title {
    font-size: 6rem;
    line-height: 7rem;
  }
  .applications-grid {
    display: flex;
    flex-direction: column-reverse;
  }
  .padding-r-5 {
    padding-right: 2rem;
  }
  .padding-l-5 {
    padding-left: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .primary-row {
    grid-row: 1;
  }
  .grid-10 {
    display: grid;
    grid-template-columns: 1fr;
  }
  .padding-r-5,
  .padding-l-5 {
    padding: unset;
  }
  .padding-top-4 {
    padding-top: 2rem;
  }
  .padding-top-6 {
    padding-top: 3rem;
  }
  .padding-bottom-10 {
    padding-bottom: 5rem;
  }
  .padding-top-10 {
    padding-top: 5rem;
  }
  .padding-top-20 {
    padding-top: 5rem;
  }
  .main-title {
    font-size: 6rem;
    line-height: 7rem;
  }
  .description {
    font-size: 1.4rem;
    line-height: 2rem;
    margin: 2rem 0;
  }
}

@media screen and (max-width: 750px) {
  .box {
    padding: 0 2.5rem;
  }
  .main-title {
    font-size: 4rem;
    line-height: 5rem;
  }
  .description {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}
