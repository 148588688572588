.walmart-military-section {
  min-height: 100vh;
  max-height: 100vh;
  z-index: 1;
  background-color: #d2deea;
  position: relative;
}

.walmart-military-container {
  display: flex;
  height: 100vh;
  flex-direction: row;
  padding: 4rem;
  align-items: center;
  gap: 4rem;
  position: relative;
  background-image: url('../../../assets/imgs/walmartMilitary/720Background.jpg');
  background-size: cover;
  background-position: center;
}

.walmart-military-section-title-container {
  width: 100%;
  height: 100%;
  display: flex;
  order: 1;
  justify-content: center;
  z-index: 1;
  position: relative;
}

.walmart-military-section-form-container {
  width: 100%;
  height: 100%;
  display: flex;
  order: 0;
  justify-content: center;
  border-radius: 10px;
  z-index: 1;
  position: relative;
}

.walmart-military-section-title {
  font-size: 5rem;
  color: #000000;
  font-weight: bold;
  padding-bottom: 2rem;
  max-width: 100%;
}

.walmart-military-iframe {
  width: 100%;
  max-width: 80vw;
  max-width: 600px;
  height: 100%;
  border: none;
  border-radius: 10px;
}

@media screen and (max-width: 600px) {
  .walmart-military-container {
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    background-image: none;
  }
  .walmart-military-section-title {
    font-size: 3rem;
    max-width: 500px;
  }
  .walmart-military-section-title-container {
    height: fit-content;
    order: 0;
  }
  .walmart-military-section-form-container {
    order: 1;
  }
  .walmart-military-iframe {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .walmart-military-container {
    background-image: url('../../../assets/imgs/walmartMilitary/960Background.jpg');
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .walmart-military-container {
    background-image: url('../../../assets/imgs/walmartMilitary/960Background.jpg');
  }
}

@media screen and (min-width: 1200px) and (max-width: 1520px) {
  .walmart-military-container {
    background-image: url('../../../assets/imgs/walmartMilitary/1520Background.jpg');
  }
}

@media screen and (min-width: 1520px) and (max-width: 2500px) {
  .walmart-military-container {
    background-image: url('../../../assets/imgs/walmartMilitary/1920Background.jpg');
  }
}

@media screen and (max-width: 1500px) {
  .walmart-military-section-title {
    font-size: 3rem;
    max-width: 500px;
  }
}
