.avatars {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.avatars > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 auto;
}

.avatars img {
  width: 270px;
  height: 270px;
  object-fit: cover;
  border-radius: 10px;
}

.owner-name {
  font-size: 2.75rem;
  max-width: 270px;
  font-weight: bold;
  line-height: 4.3rem;
  font-style: normal;
  text-align: center;
  color: #ffffff;
}

.owner-position {
  font-size: 1.375rem;
  font-weight: normal;
  line-height: 2.5rem;
  font-style: normal;
  text-align: center;
  color: #3db6fb;
}

@media screen and (min-width: 1100px) and (max-width: 1765px) {
  .avatars {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 850px;
    justify-content: center;
    margin: 0 auto;
  }
}

@media screen and (max-width: 750px) {
  .avatars {
    gap: 0px;
  }
  .avatars img {
    width: 240px;
    height: 240px;
  }
  .owner-name {
    max-width: 240px;
  }
}

@media screen and (max-width: 425px) {
  .avatars img {
    width: 200px;
    height: 200px;
  }
  .owner-name {
    max-width: 200px;
  }
}
