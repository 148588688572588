.hero-section {
  background-image: url('../../../assets/imgs/hero/Background.jpeg');
  height: 100vh;
  min-height: 725px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background-size: cover;
}
.hero-section .box {
  height: 100%;
  display: flex;
  align-items: center;
}
.hero-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.hero-section .main-title {
  margin-bottom: 5rem;
  margin-top: 5rem;
}

@media screen and (max-width: 1250px) {
  .hero-section {
    min-height: 625px;
  }
  .hero-grid {
    grid-template-columns: 1fr;
  }
  .hero-section .main-title {
    margin-bottom: 80px;
  }
  .hero-section .hero-content {
    max-width: unset;
  }
}