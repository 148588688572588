.customer-section {
  min-height: 100vh;
  padding: 4rem;
  color: white;
  position: relative;
  overflow: hidden;
  padding-top: 10rem;
  z-index: 1;
}

.customer-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4rem;
}

.text-container {
  flex: 0 0 40%;
  padding-top: 2rem;
  z-index: 2;
}

.sub-title {
  font-size: 2rem;
  color: #3db6fb;
  margin-bottom: 2rem;
}

.text {
  font-size: 1.5rem;
  color: #ffffff;
}

.integration-section {
  margin-top: 3rem;
}

.integration-title {
  font-size: 1.5rem;
  color: white;
  margin-bottom: 1rem;
}

.integration-list {
  list-style: inside;
  padding: 0;
}

.integration-list li {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #ffffff;
}

.integration-list li::marker {
  content: '';
}

.integration-list {
  list-style: disc;
  padding: 0;
  margin: 0;
}
.integration-list li::before {
  content: '•';
  color: #ffffff;
  margin-right: 0.5rem;
  font-size: 2rem;
}

.coursera-logo {
  max-height: 2rem;
  height: auto;
  vertical-align: middle;
}

.image-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 4rem;
  overflow: hidden;
}

.image-content-container {
  z-index: 2;
  position: relative;
}

.image-wrapper {
  position: relative;
  width: 100%;
}

.image-container p {
  padding-right: 10rem;
}

.image {
  max-width: 100%;
  height: auto;
  border-radius: 1rem;
  display: block;
  margin-bottom: 2rem;
}

.arrow {
  position: absolute;
  bottom: -50px;
  right: 30px;
  z-index: 2;
}

.arrow svg {
  width: 100px;
  height: 100px;
}

@media screen and (min-width: 1024px) {
  .customer-main-title {
    justify-content: flex-start;
  }
  
}

@media screen and (max-width: 1024px) {
  .customer-section {
    padding-left: 4rem;
  }
  .customer-container {
    flex-direction: column;
  }
  .sub-title {
    margin-top: 2rem;
  }
  .text-container {
    order: 0;
  }
  .image-container {
    order: 1;
  }
  .image-container p {
    padding-right: 7rem;
  }
}

@media screen and (max-width: 768px) {
  .text-container {
    flex: 1;
    padding-right: 0;
  }

  .customer-section {
    padding: 2rem;
  }

  .sub-title {
    font-size: 1.75rem;
    margin-top: 2rem;
  }

  .text,
  .integration-title,
  .integration-list li,
  .integration-list li::before {
    font-size: 1.5rem;
  }

  .arrow svg {
    width: 75px;
    height: 75px;
  }
}
