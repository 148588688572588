.news-grid {
  grid-gap: unset;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}
.img-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.logo-nsf {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
  max-width: 425px;
}

@media screen and (max-width: 750px) {
  .news-grid {
    display: unset;
  }
  .logo-nsf {
    width: 100%;
    max-width: 200px;
  }
}
