.dropdown-wrapper {
}
.dropdown-item {
  border-bottom: 1px solid #78849e;
  padding: 1rem 5px 1rem 0;
}
.dropdown-title {
  font-size: 2.5rem;
  font-weight: bold;
  position: relative;
  line-height: 3rem;
  cursor: pointer;
  color: #3db6fb;
}
.dropdown-item:last-child {
  border-bottom: unset;
}
.down {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  position: absolute;
  right: 3px;
  top: 10px;
  transition: 0.5s ease-out;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.open-list {
  transform: rotate(225deg)!important;
  -webkit-transform: rotate(225deg)!important;;
}

@media screen and (max-width: 750px) {
  .dropdown-wrapper {
    margin-bottom: 2rem;
  }
  .dropdown-title {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
  .down {
    top: 3px;
  }
}
