.glow {
  position: absolute;
  max-height: 100vh;
  z-index: -1;
}

.left-glow {
  left: 0;
}

.right-glow {
  right: 0;
  transform: scaleX(-1);
}


@media screen and (max-width: 1400px) {
  .glow {
    max-height: 70vh;
  }
}

@media screen and (max-width: 1024px) {
  .glow {
    max-height: 60vh;
  }
}

@media screen and (max-width: 750px) {
  .glow {
    max-height: 50vh;
  }
} 