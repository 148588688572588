.carousel-elements {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}
.carousel-slide {
  width: calc(100% - 5rem);
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
}
.hid-img {
  opacity: 0;
}

@media screen and (max-width: 1024px) {
  .carousel-slide {
    width: 100%;
  }
}
